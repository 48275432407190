import React from "react";

export default function BNB() {
  return (
    <img
      src="/assets/tokens/bnb.png"
      className={`w-[18px] h-[18px] my-auto`}
    />
  );
}
