import React from "react";
import "./../styles/roadmap.css";

export default function Roadmap() {
  return (
    <div className="max-w-[1200px] mx-auto container">
      <div className="mt-16">
        <p className="text-3xl text-center font-bold">
          Snow Bank's Vision and Expansion Into a Multi Chain DEX
        </p>
        <p className="text-center max-w-[600px] mx-auto mt-3">
          As Snow Bank solidifies its position in DeFi, the next milestone is
          the establishment of our own Decentralized Exchange (DEX).
        </p>
      </div>
      <div className="space-y-8 mt-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">
                Early December - Presale Phase
              </time>
            </div>
            <div className="text-white text-sm">
              <span className="font-bold"> Objective: </span>Initiate the
              presale of SNOW tokens and yield earning Snow Bank NFT's, offering
              early access to investors and community members.
              <br /> <span className="font-semibold">Hype:</span> Engage with
              early adopters, gather feedback, and build anticipation for the
              official launch.
              <br /> <span className="font-bold">Beta Testing:</span> Ensuring
              the durability of the Snow Bank platform, fine-tuning all
              technical and operational aspects.
              <br /> <span className="font-bold">Audit:</span> It is important
              to the Snow Team and investors that we get our contracts audited
              before launch.
              <br />
              <span className="font-bold"> Marketing:</span> Paid sponsors,
              YouTubers, Advertisements.
              <br />
              <span className="font-bold">Community:</span> Growing our
              Telegram, Discord, Twitter.
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">
                Mid December - Official Launch
              </time>
            </div>
            <div className="text-white text-sm">
              Launch of Snow Bank: Snow Bank goes live - marking the start of
              yield farming SNOW on BNB smart chain.
              <br /> SNOW/BNB LP Pool: Zap into the high APR SNOW/BNB LP pool
              along with exclusive staking options for SNOW and SNOW NFTs.
              <br /> Community Engagement: Strengthen community ties through
              interactive sessions, AMAs, and continuous support.
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">
                Post-Launch - Snow Bank Releas
              </time>
            </div>
            <div className="text-white text-sm">
              Launch of Snow Bank: Officially release Snow Bank and adding
              liquidity to SNOW token. <br />
              Promo: Heavy round of marketing and advertising.
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">
                Following Snow Bank Release - Presale for DEX
              </time>
            </div>
            <div className="text-white text-sm">
              Announcement: Announce the upcoming presale for Snow Bank's
              Decentralized Exchange (DEX).
              <br />
              Presale Goals: Aim to attract broader participation, laying the
              financial and community foundation for the DEX.
              <br />
              Preparation for DEX Launch: Finalize the development and testing
              of the DEX, ensuring a seamless and secure experience for users.
              <br />
              DEX Launch: The official launch of our cross-chain DEX.
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">The Future</time>
            </div>
            <div className="text-white text-sm">
              We plan to use our own DEX to help other projects, and use our own
              DEX for all of our projects across multiple chains as we expand
              into new and interesting project.
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 snow_effect text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
            >
              <path
                fillRule="nonzero"
                d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"
              />
            </svg>
          </div>
          <div className="w-[calc(100%-2rem)] md:w-[calc(50%-0.5rem)] p-4 rounded snow_effect ">
            <div className="flex items-center justify-between space-x-2 mb-1">
              <div className="font-bold"></div>
              <time className="font-caveat font-medium text-white">Conclusion</time>
            </div>
            <div className="text-white text-sm">
              The expansion into a DEX marks a pivotal chapter in our journey.
              By building this platform, Snow Bank is not just diversifying our
              services but also reinforcing our commitment to creating a robust,
              user-centric DeFi ecosystem. This step will lay the groundwork for
              Snow Bank's future innovations and continued success in the DeFi
              space.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
