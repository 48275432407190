const contractAddresses = {
  masterChef: "0x38C08E2c2313E6b593200AAf54C18526dF23BF3D",
  multiCall: "0xcD6c042a827E33967916fCB9f396904dA307e9ae",
  zap: "0x5511F333dD6090e1DE63c95bfaCba9Cb8ed22F8C",
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  factory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  snow: "0x5Ca5Db736E49e884159b01EA5048c2778dfA8660",
  wbnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  usdc: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  nft: "0x7890B3C2e7cf128Ff1591DE0BA933DA572E4bD39",
  usdcLp: "0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b",
  snowWbnblp: "0x96bc103f5F8Fdd87991903F3beE1FC5a90C6F76a",
  presaleContract: "0x7747e5c6AC31f19b0096B5ABD41095FBcB07766D",
  presaleFork: "0x7747e5c6AC31f19b0096B5ABD41095FBcB07766D",
};

export default contractAddresses;