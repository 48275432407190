import tokens from "./tokens";
import addresses from "constants/addresses";

export const liquidityList = [
  {
    pid: 0,
    lpSymbol: tokens.snow.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.snow.address,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.bnb.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.bnb.address,
    decimals: 18,
    logoA: tokens.bnb.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.wbnb.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wbnb.address,
    decimals: 18,
    logoA: tokens.wbnb.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WBNB-SNOW",
    isTokenOnly: false,
    lpAddresses: addresses.snowWbnblp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wbnb.logo,
  },
];

export const zapList = [
  {
    pid: 0,
    lpSymbol: tokens.snow.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.snow.address,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: "",
  },
  {
    pid: 0,
    lpSymbol: tokens.bnb.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.bnb.address,
    decimals: 18,
    logoA: tokens.bnb.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: tokens.wbnb.symbol,
    isTokenOnly: true,
    lpAddresses: tokens.wbnb.address,
    decimals: 18,
    logoA: tokens.wbnb.logo,
    logoB: "",
  },
  {
    pid: 1,
    lpSymbol: "WBNB-SNOW",
    isTokenOnly: false,
    lpAddresses: addresses.snowWbnblp,
    decimals: 18,
    logoA: tokens.snow.logo,
    logoB: tokens.wbnb.logo,
  },
];

const farmsConfig = [
  {
    pid: 0,
    lpSymbol: "WBNB-SNOW",
    lpAddresses: addresses.snowWbnblp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.wbnb,
    logoA: tokens.snow.logo,
    logoB: tokens.wbnb.logo,
  },
  {
    pid: 1,
    lpSymbol: "WBNB-USDC",
    lpAddresses: addresses.usdcLp,
    isTokenOnly: false,
    isNFTPool: false,
    token: tokens.usdc,
    quoteToken: tokens.wbnb,
    logoA: tokens.wbnb.logo,
    logoB: tokens.usdc.logo,
  },
  {
    pid: 2,
    lpSymbol: "SNOW",
    lpAddresses: addresses.snow,
    isTokenOnly: true,
    isNFTPool: false,
    token: tokens.snow,
    quoteToken: tokens.snow,
    logoA: tokens.snow.logo,
    logoB: tokens.snow.logo,
  },
  // {
  //   pid: 3,
  //   lpSymbol: "WBNB",
  //   lpAddresses: addresses.wbnb,
  //   isTokenOnly: true,
  //   isNFTPool: false,
  //   token: tokens.wbnb,
  //   quoteToken: tokens.wbnb,
  //   logoA: tokens.wbnb.logo,
  //   logoB: tokens.wbnb.logo,
  // },
  {
    pid: 4,
    lpSymbol: "SNOWNFT",
    lpAddresses: addresses.nft,
    isTokenOnly: true,
    isNFTPool: true,
    token: tokens.nft,
    quoteToken: tokens.snow,
    logoA: "/assets/tokens/nft.png",
    logoB: "",
  },
];

export default farmsConfig;
