import React from "react";

export default function ComingSoon() {
  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-200px)]">
      <div>
        <h1 className="text-5xl text-white font-bold mb-8 animate-pulse text-center">
          Coming Soon
        </h1>
        <p className="text-white text-lg mb-8 text-center">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
      </div>
    </div>
  );
}
